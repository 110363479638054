<template>
    <div>
      <ServerError v-if="ServerError" />
      <PageLoader :storage="appLoading" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-start pt-3 pl-2 pr-5>
        <v-flex align-self-center xs6 sm9 md10 text-left>
          <span class="itemHeading1">SEOC</span>
        </v-flex>
        <v-flex align-self-center xs6 sm3 md2 lg2 xl1 px-3>
          <v-btn
            block
            tile
            depressed
            outlined
            color="#000000"
            :ripple="false"
            @click="showAddDialog = true"
            class="itemValue"
          >
            Add New SEOC
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <!-- <v-flex xs12 v-if="projectdata && projectdata.length > 0">
          <v-layout wrap justify-start px-5>
            <v-flex xs12 pt-4>
              <v-layout wrap>
                <v-flex
                  xs12
                  lg4
                  sm4
                  md4
                  v-for="(item, index) in projectdata"
                  :key="index"
                  pa-2
                >
                  <v-card class="mb-4">
                    <v-card-title>
                      <span class="itemform">{{ item.name }}</span>
                    </v-card-title>
                    <v-card-subtitle>
                      <span class="itemform">{{ item.phone }}</span>
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-btn
                        color="green"
                        style="font-family: poppinsmedium"
                        small
                        plain
                        @click="openEditDialog(item)"
                      >
                        <v-icon small>mdi-pencil</v-icon><span>Edit</span>
                      </v-btn>
                      <v-btn
                        color="error"
                        style="font-family: poppinsmedium"
                        small
                        plain
                        @click="openDeleteDialog(item)"
                      >
                        <v-icon small>mdi-delete</v-icon><span>Delete</span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex> -->
        <v-flex xs12 pt-4 v-if="projectdata && projectdata.length > 0">
          <v-card>
            <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left itemform tableheadingclass">Name</th>
                  <th class="text-left itemform tableheadingclass">Phone Number</th>
                  <!-- <th class="text-left itemform tableheadingclass">Edit</th> -->
                  <!-- <th class="text-left itemform tableheadingclass">Delete</th> -->
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in projectdata" :key="index">
                  <td class="itemform">{{ item.name }}</td>
                  <td class="itemform">{{ item.phone }}</td>
                  <td class="text-left">
                    <v-btn
                      tile
                      small
                      depressed
                      outlined
                      color="#FF1313"
                      :ripple="false"
                      @click="openEditDialog(item)"
                      class="itemValue"
                    >
                      <span style="color: #ff1313"> Edit </span>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      tile
                      small
                      depressed
                      outlined
                      color="#FF1313"
                      :ripple="false"
                      @click="openDeleteDialog(item)"
                      class="itemValue"
                    >
                      <span style="color: #ff1313"> Delete </span>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          </v-card>
        </v-flex>
        <v-flex xs12 v-else pl-3>
          <span>No SEOC Users Found!</span>
        </v-flex>
      </v-layout>
  
      <!-- Add New User Dialog -->
      <v-dialog v-model="showAddDialog" max-width="500px">
        <v-card>
          <v-card-title class="itemHeading">Add New SEOC</v-card-title>
          <v-card-text>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-text-field
                  label="Name"
                  v-model="name"
                  placeholder="Enter Name"
                  :rules="[(v) => !!v || 'Name is required', (v) => v.length <= 18 || 'Name must be 18 characters or less']"
                  outlined
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="Username"
                  v-model="username"
                  placeholder="Enter Username"
                  :rules="[(v) => !!v || 'Username is required', (v) => v.length <= 10 || 'Username must be 10 characters or less']"
                  outlined
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="Phone Number"
                  v-model="phone"
                  placeholder="Enter Phone Number"
                  type="number"
                  class="inputPrice itemform"
                  :rules="[
                    (v) => !!v || 'Phone number is required',
                    (v) => /^\d{10}$/.test(v) || 'Phone number must be 10 digits',
                  ]"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="Email"
                  v-model="email"
                  placeholder="Enter Email"
                  :rules="[
                    (v) => !!v || 'Email is required',
                    (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                  ]"
                  class="itemform"
                  dense
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  outlined
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  :rules="passwordRules"
                  label="Password"
                  hint="At least 8 characters, with one uppercase, one lowercase, one number, and one special character"
                  counter
                  @click:append="show1 = !show1"
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  outlined
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-2"
                  :rules="[confirmPasswordRule]"
                  label="Confirm Password"
                  @click:append="show2 = !show2"
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeAddDialog"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <!-- <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="saveUser"
              :disabled="!canSave"
              class="itemValue"
            >
              <span style="color: #fff">Save</span>
            </v-btn> -->
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="saveUser"
              disabled
              class="itemValue"
            >
              <span style="color: #fff">Save</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Edit User Dialog -->
      <v-dialog v-model="showEditDialog" max-width="500px">
        <v-card>
          <v-card-title class="itemHeading">Edit SEOC</v-card-title>
          <v-card-text>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-text-field
                  label="Name"
                  v-model="editUser.name"
                  placeholder="Enter Name"
                  :rules="[(v) => !!v || 'Name is required', (v) => v.length <= 18 || 'Name must be 18 characters or less']"
                  outlined
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="UserName"
                  v-model="editUser.username"
                  placeholder="Enter UserName"
                  :rules="[(v) => !!v || 'Username is required', (v) => v.length <= 10 || 'Username must be 10 characters or less']"
                  outlined
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="Phone Number"
                  v-model="editUser.phone"
                  placeholder="Enter Phone Number"
                  type="number"
                  class="inputPrice itemform"
                  :rules="[
                    (v) => !!v || 'Phone number is required',
                    (v) => /^\d{10}$/.test(v) || 'Phone number must be 10 digits',
                  ]"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="Email"
                  v-model="editUser.email"
                  placeholder="Enter Email"
                  :rules="[
                    (v) => !!v || 'Email is required',
                    (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                  ]"
                  outlined
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeEditDialog"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="updateUser"
              class="itemValue"
            >
              <span style="color: #fff">Update</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Confirm Delete Dialog -->
      <v-dialog v-model="confirmDeleteDialog" max-width="500px">
        <v-card>
          <v-card-title class="itemHeading">Confirm Delete</v-card-title>
          <v-card-text
            >Are you sure you want to delete this SEOC?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="confirmDeleteDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteUser"
              class="itemValue"
            >
              <span style="color: #fff">Delete</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        showAddDialog: false,
        showEditDialog: false,
        confirmDeleteDialog: false,
        timeout: 3000,
        msg: null,
        show1: false,
        show2: false,
        name: "",
        username: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        editUser: {
          name: "",
          phone: "",
          email: "",
          username: "",
        },
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 8 || "Min 8 characters",
          emailMatch: () => `The email and password you entered don't match`,
        },
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) => v.length >= 8 || "Min 8 characters",
          (v) => /[A-Z]/.test(v) || "Must contain an uppercase letter",
          (v) => /[a-z]/.test(v) || "Must contain a lowercase letter",
          (v) => /[0-9]/.test(v) || "Must contain a number",
          (v) => /[@$!%*?&#]/.test(v) || "Must contain a special character",
        ],
        confirmPasswordRule: (v) => v === this.password || "Passwords must match",
        deleteUserItem: null,
        projectdata: [],
      };
    },
    computed: {
      canSave() {
        // Check if passwords match and if password rules are met
        return (
          this.password &&
          this.confirmPassword &&
          this.password === this.confirmPassword &&
          this.passwordRules.every((rule) => rule(this.password) === true)
        );
      },
    },
    beforeMount() {
      // this.getData();
    },
    methods: {
      closeAddDialog() {
        this.name = "";
        this.username = "";
        this.phone = "";
        this.email = "";
        this.password = "";
        this.confirmPassword = "";
        this.showAddDialog = false;
      },
      saveUser() {
        if (
          !this.name ||
          !this.username ||
          !this.phone ||
          !this.email ||
          !this.password ||
          !this.confirmPassword
        ) {
          this.msg = "All fields are mandatory!";
          this.showSnackBar = true;
          return;
        }
        if( this.name.length > 18 ){
          this.msg = "Name must be 18 characters or less!";
          this.showSnackBar = true;
          return;
        }
        if( this.username.length > 10 ){
          this.msg = "Username must be 10 characters or less!";
          this.showSnackBar = true;
          return;
        }
        const emailRegex = /.+@.+\..+/;
        const phoneRegex = /^\d{10}$/;
        if (!emailRegex.test(this.email)) {
          this.msg = "Email must be valid";
          this.showSnackBar = true;
          return;
        }
        if (!phoneRegex.test(this.phone)) {
          this.msg = "Phone number must be 10 digits";
          this.showSnackBar = true;
          return;
        }
  
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/user/emergencyuser/create",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            name: this.name,
            username: this.username,
            phone: this.phone,
            email: this.email,
            password: this.password,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = "SEOC added successfully!";
              this.showSnackBar = true;
              this.appLoading = false;
              this.showAddDialog = false;
              this.closeAddDialog();
              this.getData();
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/users/emergency/all",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.projectdata = response.data.data;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      openEditDialog(item) {
        this.editUser = { ...item };
        this.showEditDialog = true;
      },
      closeEditDialog() {
        this.showEditDialog = false;
        this.editUser = { name: "", phone: "", email: "", username: "" };
      },
      updateUser() {
        if (
          !this.editUser.name ||
          !this.editUser.username ||
          !this.editUser.phone ||
          !this.editUser.email
        ) {
          this.msg = "All fields are mandatory!";
          this.showSnackBar = true;
          return;
        }
        if( this.editUser.name.length > 18 ){
          this.msg = "Name must be 18 characters or less!";
          this.showSnackBar = true;
          return;
        }
        if( this.editUser.username.length > 10 ){
          this.msg = "Username must be 10 characters or less!";
          this.showSnackBar = true;
          return;
        }
        const emailRegex = /.+@.+\..+/;
        const phoneRegex = /^\d{10}$/;
        if (!emailRegex.test(this.editUser.email)) {
          this.msg = "Email must be valid";
          this.showSnackBar = true;
          return;
        }
        if (!phoneRegex.test(this.editUser.phone)) {
          this.msg = "Phone number must be 10 digits";
          this.showSnackBar = true;
          return;
        }
  
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/user/emergencyuser/edit",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            name: this.editUser.name,
            username: this.editUser.username,
            phone: this.editUser.phone,
            email: this.editUser.email,
            id: this.editUser._id,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = "SEOC updated successfully!";
              this.showSnackBar = true;
              this.appLoading = false;
              this.showEditDialog = false;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      deleteUser() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/user/emergencyuser/delete",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.deleteUserItem._id,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = "SEOC deleted successfully!";
              this.showSnackBar = true;
              this.appLoading = false;
              this.confirmDeleteDialog = false;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      openDeleteDialog(item) {
        this.deleteUserItem = item;
        this.confirmDeleteDialog = true;
      },
    },
  };
  </script>
  <style>
  .inputPrice input::-webkit-outer-spin-button,
  .inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .tableheadingclass{
    font-size: 18px !important;
  }
  </style>
  